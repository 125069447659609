<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback />  {{ $t('WalletReportHistory') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model.trim="chanelPaySelected"
            :items="chanelPayList"
            :label="$t('channel_pay')"
            outlined
            dense
            item-text="shop_bank_name"
            item-value="shop_bank_id"
            @change="addPayload()"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="3"
        >
          <v-dialog
            ref="datePickerStart"
            v-model.trim="isShowDate1"
            :return-value.sync="dateStart"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStart"
                :label="$t('date')"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="dateStart"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.datePickerStart.save(dateStart)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate1 = false"
              >
                {{ $t("cancel") }}
              </v-btn>

              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-dialog
            ref="datePickerEnd"
            v-model.trim="isShowDate2"
            :return-value.sync="dateEnd"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="dateEnd"
                :label="$t('to')"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-tooltip
                    color="#212121"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="dateStart = '';dateEnd=''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t("all_dates") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="dateEnd"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>

              <v-btn
                text
                color="primary"
                @click="$refs.datePickerEnd.save(dateEnd)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate2 = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.date`]="{ item }">
          <span :class="item.date == $t('total') ? 'font-weight-bold primary--text':''">
            {{ item.date }}
          </span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span :class="item.date == $t('total') ? 'font-weight-bold primary--text':''">
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { mdiCalendarBlank, mdiFileExcelOutline } from '@mdi/js'
import { i18n } from '@/plugins/i18n'
import { reportWallet, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import paymentChannel from '@/api/systemSetting/paymentChannel'
import { sumdate } from '@/plugins/filters'

export default {
  components: {
    Comeback,
  },
  setup() {
    const moment = require('moment')
    const chanelPaySelected = ref('')
    const chanelPayList = ref([])
    const dateStart = ref(moment(new Date()).format('YYYY-MM-DD'))
    const dateEnd = ref(moment(new Date()).format('YYYY-MM-DD'))
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)

    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const exportLoading = ref(false)
    const options = ref({})
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('user'), value: 'user_fullname', width: 180 },
      { text: i18n.t('account_alias'), value: 'shop_bank_name', width: 180 },
      { text: i18n.t('list'), value: 'list', width: 300 },
      { text: i18n.t('date'), value: 'date', width: 140 },
      {
        text: i18n.t('net_amount'), value: 'amount', width: 180, align: 'end',
      },
    ])

    paymentChannel.paymentChannelList({
      searchtext: '',
      shop_bank_check: '',
    }).then(res => {
      chanelPayList.value = res.data
      chanelPayList.value.unshift({
        shop_bank_id: '',
        shop_bank_name: i18n.t('all'),
      })
    })

    onMounted(() => {
      addPayload()
    })

    const addPayload = () => {
      payload.value = {
        start: dateStart.value,
        end: dateEnd.value,
        shop_bank_id: chanelPaySelected.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { data } = await reportWallet(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    watch([dateStart, dateEnd], () => {
      addPayload()
    })

    const exportExcel = async () => {
      exportLoading.value = true
      const dataExport = await removeComma(JSON.parse(JSON.stringify(dataTableList.value)))
      const fileName = `${i18n.t('HistoryReportWallet')} ${i18n.t(
        'since',
      )} ${sumdate(dateStart.value)} ${i18n.t('to')} ${sumdate(dateEnd.value)}.xlsx`

      //* เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx *//
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${i18n.t('HistoryReportWallet')}`],
        [
          '#',
          `${i18n.t('user')}`,
          `${i18n.t('account_alias')}`,
          `${i18n.t('list')}`,
          `${i18n.t('date')}`,
          `${i18n.t('net_amount')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'user_fullname',
          'shop_bank_name',
          'list',
          'date',
          'amount',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }]
      const wscols = [
        { wch: 8 },
        { wch: 25 },
        { wch: 20 },
        { wch: 40 },
        { wch: 15 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${i18n.t('HistoryReportWallet')}`,
      )

      /* พิมร์ files */

      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      moment,
      payload,
      loading,
      exportLoading,
      options,
      dataTableList,
      columns,
      chanelPaySelected,
      chanelPayList,
      dateStart,
      dateEnd,
      isShowDate1,
      isShowDate2,
      addPayload,
      exportExcel,
      mdiFileExcelOutline,
      icons: { mdiCalendarBlank },
    }
  },
}
</script>
